import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

interface DocumentTitleContextType {
  documentTitle: string;
  setDocumentTitle: Dispatch<SetStateAction<string>>;
  setDocumentTitlePrefix: Dispatch<SetStateAction<string>>;
}

const DocumentTitleContext = createContext<
  DocumentTitleContextType | undefined
>(undefined);

interface DocumentTitleProviderProps {
  children: React.ReactNode;
}

export default function DocumentTitleProvider({
  children,
}: DocumentTitleProviderProps) {
  const [documentTitle, setDocumentTitle] = useState<string>("");
  const [documentTitlePrefix, setDocumentTitlePrefix] = useState<string>("");

  useEffect(() => {
    const prefix = documentTitlePrefix ? documentTitlePrefix + " - " : "";
    document.title = prefix + documentTitle;
  }, [documentTitle, documentTitlePrefix]);

  return (
    <DocumentTitleContext.Provider
      value={{ documentTitle, setDocumentTitle, setDocumentTitlePrefix }}
    >
      {children}
    </DocumentTitleContext.Provider>
  );
}

export const useDocumentTitle = (): DocumentTitleContextType => {
  const context = useContext(DocumentTitleContext);
  if (context === undefined) {
    throw new Error(
      "useDocumentTitle must be used within a DocumentTitleProvider"
    );
  }
  return context;
};
